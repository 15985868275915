import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { generateCertificate } from "../../../api/certificate";
import { getLevels } from "../../../api/levels";
import { getUsersByNameAndLocation } from "../../../api/user";
import useAuth from "../../../context/useAuth";
import Level from "../../../interfaces/level";
import User from "../../../interfaces/user";

interface FormData {
  language: string;
  level: string;
  firstName: string;
  lastName: string;
  dateOfCertification: Dayjs | null;
  location: string;
  numberOfLessons: string;
  certificateCode: string;
  teacher: string;
}

const SingleCertificate: React.FC = () => {
  const { locations } = useAuth();
  const [levels, setLevels] = useState<Level[]>([]);
  const [teachers, setTeachers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
    getValues,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      language: "",
      level: "",
      firstName: "",
      lastName: "",
      dateOfCertification: null,
      location: "",
      numberOfLessons: "",
      certificateCode: "",
      teacher: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [levelsRes, teachersRes] = await Promise.all([
          getLevels(),
          getUsersByNameAndLocation(
            ["4"],
            undefined,
            undefined,
            undefined,
            true
          ),
        ]);

        if ("error" in levelsRes) {
          setError(levelsRes.message);
        } else {
          setLevels(levelsRes.data);
        }

        if ("error" in teachersRes) {
          setError(teachersRes.message);
        } else {
          setTeachers(teachersRes.data);
        }
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setUploading(true);

      const certificateData = {
        language: data.language,
        level: data.level,
        firstName: data.firstName,
        lastName: data.lastName,
        date: data.dateOfCertification
          ? data.dateOfCertification.format("DD.MM.YYYY")
          : "",
        location: data.location,
        hours: Number(data.numberOfLessons),
        code: data.certificateCode,
        teacher: data.teacher,
      };

      const result = await generateCertificate(certificateData);

      if (result.error) {
        console.error(result.message);
      } else {
        reset();
        console.log("Certificate generated successfully:", result.message);
      }
    } catch (error) {
      console.error("Error generating or uploading certificate:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" mb={2}>
        Generate Single User Certificate
      </Typography>

      <Controller
        name="language"
        control={control}
        rules={{ required: "Language is required" }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select {...field} labelId="language-select-label" label="Language">
              <MenuItem value="French">French</MenuItem>
              <MenuItem value="German">German</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="level"
        control={control}
        rules={{ required: "Level is required" }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="level-select-label">Level</InputLabel>
            <Select
              {...field}
              labelId="level-select-label"
              label="Level"
              disabled={loading}
            >
              {loading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : error ? (
                <MenuItem value="">{error}</MenuItem>
              ) : (
                levels.map((level) => (
                  <MenuItem key={level._id} value={level.level}>
                    {level.level}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      />

      <Box display="flex" justifyContent="space-between" mb={2} gap={2}>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: "First Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="First Name"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: "Last Name is required" }}
          render={({ field }) => (
            <TextField {...field} label="Last Name" fullWidth margin="normal" />
          )}
        />
      </Box>

      <Controller
        name="dateOfCertification"
        control={control}
        rules={{ required: "Date of Certification is required" }}
        render={({ field }) => (
          <DatePicker
            {...field}
            label="Date of Certification"
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
            sx={{ width: "100%" }}
          />
        )}
      />

      <Controller
        name="location"
        control={control}
        rules={{ required: "Location is required" }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="locations-select-label">Location</InputLabel>
            <Select
              {...field}
              labelId="locations-select-label"
              label="Location"
              disabled={loading}
            >
              {loading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : error ? (
                <MenuItem value="">{error}</MenuItem>
              ) : (
                locations.map((location) => (
                  <MenuItem key={location.value} value={location.name}>
                    {location.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="numberOfLessons"
        control={control}
        rules={{ required: "Number of Lessons is required" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Number of Lessons"
            type="number"
            fullWidth
            margin="normal"
          />
        )}
      />

      <Controller
        name="certificateCode"
        control={control}
        rules={{ required: "Certificate Code is required" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Certificate Code"
            fullWidth
            margin="normal"
          />
        )}
      />

      <Controller
        name="teacher"
        control={control}
        rules={{ required: "Teacher is required" }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="teacher-select-label">Teacher</InputLabel>
            <Select
              {...field}
              labelId="teacher-select-label"
              label="Teacher"
              disabled={loading}
            >
              {loading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : error ? (
                <MenuItem value="">{error}</MenuItem>
              ) : (
                teachers.map(({ _id, first_name, last_name }) => (
                  <MenuItem key={_id} value={`${first_name} ${last_name}`}>
                    {first_name} {last_name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2, width: "300px" }}
        disabled={!isValid || uploading}
      >
        {uploading ? <CircularProgress size={25} /> : "Generate Certificate"}
      </Button>
    </Box>
  );
};

export default SingleCertificate;
