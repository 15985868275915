import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { addGroupStudent } from "../../../api/group";
import { getStudents } from "../../../api/user";
import GroupStudent from "../../../interfaces/groupUser";
import useDebounce from "../../../utils/useDebounce";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pt: 2,
};

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  groupId: string;
  setData: Dispatch<SetStateAction<GroupStudent[]>>;
  groupLocation: string;
}

interface StudentOptions {
  value: number;
  name: string;
  email?: string;
}

interface IFormInput {
  student_id: string;
}

const schema = yup
  .object({
    student_id: yup.string().required(),
  })
  .required();

const GroupUserModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  groupId,
  setData,
  groupLocation,
}) => {
  const {
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const [isFetching, setIsFetching] = useState<string>("");
  const [students, setStudents] = useState<StudentOptions[]>([]);
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const [records, setRecords] = useState(0);

  const debouncedSearch = useDebounce(searchInput);

  const changePage = (value: number) => {
    setPage((prev) => prev + value);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    let _data = {
      ...data,
      group_id: groupId,
    };
    setIsFetching(data.student_id);
    const res = await addGroupStudent(_data);
    if ("error" in res) {
      setIsFetching("");
      return toast.error(res.message);
    }
    setData((prevState) => [...prevState, { ...res, id: res.student._id }]);
    setIsFetching("");
    toast.success("Student added to group");
  };

  const handleClose = () => {
    setOpen(false);
    setPage(1);
    setSearchInput("");
  };

  const getStudentsByNameAndLocation = async () => {
    const { data: res, records } = await getStudents(
      page,
      [groupLocation],
      debouncedSearch
    );
    if ("error" in res) {
      return toast.error(res.message);
    } else {
      let _students = res.map((students: any) => {
        return {
          value: students._id,
          name: students.first_name + " " + students.last_name,
          email: students.email,
          groupStatus: students.isMemberOfActiveGroup,
        };
      });
      setStudents(_students);
      setRecords(records);
    }
  };

  useEffect(() => {
    getStudentsByNameAndLocation();
  }, [page, debouncedSearch]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ mb: 2, display: "flex", ml: "auto", mr: 1 }}
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Students</Typography>
          <TextField
            size="small"
            sx={{ mr: 2, width: "300px" }}
            placeholder="Search by name ..."
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Box>
        <Box sx={{ my: 2, overflowX: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "auto",
                    minWidth: "200px",
                    textAlign: "left",
                    paddingBlock: "10px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    width: "auto",
                    minWidth: "200px",
                    textAlign: "left",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    width: "auto",
                    minWidth: "150px",
                    textAlign: "left",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    width: "auto",
                    minWidth: "150px",
                    textAlign: "left",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {students.map((item: any) => (
                <tr key={item.value}>
                  <td style={{ padding: "8px 0" }}>{item.name}</td>
                  <td>{item.email}</td>
                  <td style={{ color: item.groupStatus ? "red" : "green" }}>
                    {item.groupStatus ? "Not Available" : "Available"}
                  </td>
                  <td>
                    <LoadingButton
                      loading={isFetching === item.value}
                      disabled={item.groupStatus}
                      variant="contained"
                      size="small"
                      onClick={() => onSubmit({ student_id: item.value })}
                    >
                      Add Student
                    </LoadingButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            disabled={page == 0 ? true : false}
            endIcon={<ArrowDropUpIcon />}
            onClick={() => {
              if (page >= 1) changePage(-1);
            }}
          >
            Prev
          </Button>
          <Typography sx={{ mx: 2 }}>
            {page * 10} - {(page + 1) * 10} of {records}
          </Typography>
          <Button
            size="small"
            variant="outlined"
            disabled={(page + 1) * 10 >= records ? true : false}
            endIcon={<ArrowDropDownIcon />}
            onClick={() => changePage(+1)}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default GroupUserModal;
