//@ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { createTest } from "../../../../api/tests";
import Form from "../../../form/Form";

interface AddTestProps {
  groupUserID: string;
  setOpenDialog?: Dispatch<SetStateAction<boolean>>;
  getCurrentGroupUsersFilter?: (
    id: string,
    searchInput: string,
    paidMonth: { name: string; value: number }[]
  ) => void;
  paramsId?: any;
  searchInput?: any;
  paidMonth?: { name: string; value: number };
}

const addTestSchema = yup.object({
  file: yup.mixed<any>().nullable().notRequired(),
  test_name: yup.string().required(),
  evaluation: yup.string().required(),
  held_date: yup.number().required(),
  studentComment: yup.string().nullable().notRequired(),
});

const AddTest = ({
  groupUserID,
  setOpenDialog,
  getCurrentGroupUsersFilter,
  paramsId,
  searchInput,
  paidMonth,
}: AddTestProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleBoxClick = (e) => {
    e.stopPropagation();

    fileInputRef.current?.click();
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm<any>({
    resolver: yupResolver(addTestSchema),
  });
  const onSubmit = async (formData: any) => {
    try {
      const res = await createTest({ groupUserID, ...formData });
      if ("error" in res) {
        return toast.error(res.message);
      } else {
        setOpenDialog(false),
          reset(),
          getCurrentGroupUsersFilter(paramsId, searchInput, paidMonth),
          toast.success("Test Added Successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fileIsUploaded = watch("file");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <DialogTitle id="alert-dialog-title" sx={{ ml: "-25px" }}>
            Add Test
          </DialogTitle>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "150px",
              border: "1px solid #9C45A0",
              borderRadius: "4px",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                opacity: 0.7,
                transform: "scale(0.99)",
              },
              cursor: "pointer",
              mb: 3,
            }}
            onClick={handleBoxClick}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="h6" color="#9C45A0">
                {fileIsUploaded
                  ? `File Uploaded ${fileIsUploaded.name}`
                  : "Upload Test File"}
              </Typography>
              <CloudUploadIcon sx={{ fill: "#9C45A0" }} />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  if (event.target.files && event.target.files[0]) {
                    setValue("file", event.target.files[0]);
                  }
                }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <TextField
              label="Test Name*"
              fullWidth
              {...register("test_name")}
              error={errors.test_name ? true : false}
            />
            <DatePicker
              label="Date*"
              open={open}
              format="DD/MM/YYYY"
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                  onClick: () => setOpen(!open),
                  fullWidth: true,
                  error: errors.start_lecture ? true : false,
                },
              }}
              onChange={(newValue) =>
                setValue("held_date", dayjs(newValue).valueOf())
              }
              closeOnSelect
            />
          </Box>
          <TextField
            label="Evaluation*"
            fullWidth
            {...register("evaluation")}
            error={errors.evaluation ? true : false}
          />
          <TextField
            id="standard-multiline-static"
            label="Student Comment"
            multiline
            rows={4}
            fullWidth
            {...register("studentComment")}
            error={errors.studentComment ? true : false}
          />
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "Submit"}
          </LoadingButton>
        </div>
      </Form>
    </LocalizationProvider>
  );
};

export default AddTest;
