export const twoDecimalInputChange =
  (setAmount: (value: number) => void) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = (e.target as HTMLInputElement).value;

    // Remove non-numeric characters except "."
    value = value.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point
    value = value.replace(/(\..*?)\..*/g, "$1");

    // Limit to two decimal places
    value = value.replace(/(\.\d{2}).*/g, "$1");

    // Convert to a number or default to 0 if the input is empty
    setAmount(value ? parseFloat(value) : 0);
  };
